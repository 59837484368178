@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-LightItalic.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-BoldItalic.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-Heavy.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-Heavy.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-Roman.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-Roman.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-Light.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-Light.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-Bold.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-Bold.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-Italic.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-Italic.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Caecilia LT Std';
  src: url('/assets/fonts/CaeciliaLTStd-HeavyItalic.woff2') format('woff2'),
    url('/assets/fonts/CaeciliaLTStd-HeavyItalic.woff') format('woff'),
    url('/assets/fonts/CaeciliaLTStd-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/Apercu-Medium.woff2') format('woff2'),
    url('/assets/fonts/Apercu-Medium.woff') format('woff'),
    url('/assets/fonts/Apercu-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/Apercu-Regular.woff2') format('woff2'),
    url('/assets/fonts/Apercu-Regular.woff') format('woff'),
    url('/assets/fonts/Apercu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/Apercu-Bold.woff2') format('woff2'),
    url('/assets/fonts/Apercu-Bold.woff') format('woff'),
    url('/assets/fonts/Apercu-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/Apercu-Black.woff2') format('woff2'),
    url('/assets/fonts/Apercu-Black.woff') format('woff'),
    url('/assets/fonts/Apercu-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
